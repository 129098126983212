import Vue from "vue";
import Router from "vue-router";
import store from "./store";
import cookies from "vue-cookies";

Vue.use(Router);

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
        {
            name: "Dashboard",
            path: "/",
            component: () => import("@/components/dashboards/Dashboard")
        },
        {
            name: "Customers",
            path: "/customers",
            component: () => import("@/components/people/Customers")
        },
        {
            name: "Talent",
            path: "/talent",
            component: () => import("@/components/people/Talent")
        },
        {
            name: "TalentAddNew",
            path: '/talent/add',
            component: () => import('@/components/people/TalentAddNew'),
        },
        {
            name: 'TalentEdit',
            path: '/talent/:id',
            component: () => import('@/components/people/TalentEdit'),
        },
        {
            name: 'PhysicalMail',
            path: '/physical-mail',
            component: () => import('@/components/physicalmail/PhysicalMail'),
        },
        {
            name: 'PhysicalMailTemplate',
            path: '/physical-mail-template',
            component: () => import('@/components/physicalmail/PhysicalMailTemplate'),
        },
        {
            name: 'Skills',
            path: '/skills',
            component: () => import('@/components/skills/Skills'),
        },
        {
            name: 'TalentSubmissions',
            path: '/talent-submissions',
            component: () => import('@/components/submissions/TalentSubmissions'),
        },
        {
            name: 'ClientSubmissions',
            path: '/client-submissions',
            component: () => import('@/components/submissions/ClientSubmissions'),
        },
        {
            name: 'ContactSubmissions',
            path: '/contact-form-submissions',
            component: () => import('@/components/submissions/ContactSubmissions'),
        },
        {
            name: 'TalentSubmissionsScreening',
            path: '/talent-submissions-screening',
            component: () => import('@/pages/submissions/TalentSubmissionScreeningPage'),
        },
        {
            name: 'GrowthSubmissions',
            path: '/growth-submissions',
            component: () => import('@/components/submissions/GrowthSubmissions'),
        },
        {
            name: 'NetworkView',
            path: '/network-view',
            component: () => import('@/components/tables/TableNetworkView'),
        },
        {
            name: 'ProjectView',
            path: '/project-view',
            component: () => import('@/pages/projects/ProjectOverviewPage'),
        },
        {
            name: 'ProjectAdd',
            path: '/project-add',
            component: () => import('@/pages/projects/AddNewProjectPage'),
        },
        {
            name: 'ProjectConnect',
            path: '/project-connect',
            component: () => import('@/pages/projects/ConnectTalentToProjectPage'),
        },
        {
            name: 'TagView',
            path: '/tag-view',
            component: () => import('@/pages/tags/TagOverviewPage'),
        },
        {
            name: 'TagAdd',
            path: '/tag-add',
            component: () => import('@/pages/tags/TagAddNewPage'),
        },
        {
            name: 'HowWeRecruit',
            path: '/how-we-recruit',
            component: () => import('@/components/custom-pages/HowWeRecruit'),
        },
        {
            name: 'HowWeRecruitView',
            path: '/how-we-recruit-view',
            component: () => import('@/components/custom-pages/HowWeRecruitView'),
        },
        {
            name: 'SkillBench',
            path: '/talent-bench',
            component: () => import('@/components/lists/SkillBenchList'),
        },
        {
            name: "Login",
            path: "/login",
            component: () => import("@/components/auth/LoginForm")
        },
        {
            name: "404",
            path: "/404",
            component: () => import("@/components/errors/NotFound")
        },
        {
            // Always last, this should probably redirect to a 404 page that we then redirect the user back to the dashboards after a couple seconds.
            name: "Default",
            path: "*",
            redirect: "/404"
        }
    ]
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!store.state.authKeyChecked) {
            cookies.set("slashdevRedirectTo", to.fullPath, 60 * 10);
            next({
                path: "/login"
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

const DEFAULT_TITLE = "Slashdev Admin";
// eslint-disable-next-line no-unused-vars
router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });
});

export default router;
