import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#215ff6',
                secondary: '#0096C7',
                accent: '#2196f3',
                error: '#f44336',
                warning: '#ff9800',
                info: '#00bcd4',
                success: '#4caf50',
                background: '#4caf50',
                subtitle: '#757575'
            },
        },
        options: {
            customProperties: true,
        },
    },
});
