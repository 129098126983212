import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import axios from "axios";
import VueApexCharts from "vue-apexcharts";

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

Vue.use(require('vue-cookies'));
Vue.$cookies.config(process.env.VUE_APP_DEFAULT_COOKIE_EXPIRATION, '', '', process.env.VUE_APP_COOKIES_SECURE, 'Lax');

Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_API_URL
