<template>
  <v-navigation-drawer
      :mini-variant="$store.state.drawer"
      app
      dark
      clipped
      color="primary"
  >
    <v-list>
      <v-list-item
        v-for="([icon, title, to, group], i) in singleItems"
        :key="'singleItem-' + i"
        :to="to"
      >
        <v-list-item-icon>
          <v-icon>{{ icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-title>{{ title }}</v-list-item-title>
      </v-list-item>

      <v-list-group
          v-for="([icon, title, to, group, groupItems], i) in groupItems"
          :prepend-icon="icon"
          :key="'groupItem' + i"
          active-class="accent"
      >
        <template v-slot:activator>
          <v-list-item-title>{{ title }}</v-list-item-title>
        </template>

        <v-list-item
            v-for="([gTitle, gTo], i) in groupItems"
            :key="i"
            :to="gTo"
            active-class="accent"
        >
          <v-list-item-title v-text="gTitle"></v-list-item-title>
        </v-list-item>

      </v-list-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "LeftNavDrawer",

  computed: {
    singleItems() {
      let filtered = [];
      this.items.forEach(t => {
        if (t[3] === false)
          filtered.push(t);
      })
      return filtered;
    },

    groupItems() {
      let filtered = [];
      this.items.forEach(t => {
        if (t[3] === true)
          filtered.push(t)
      })
      return filtered;
    }
  },

  data: () => ({
    items: [
      ['mdi-view-dashboard-variant', 'Dashboard', '/', false],
      ['mdi-form-select', 'Form Submissions', '/404', true,
        [
          ['Bench', '/talent-bench'],
          ['Clients', '/client-submissions'],
          ['Talent Screening', '/talent-submissions-screening'],
          ['Talent', '/talent-submissions'],
          ['Contact Us', '/contact-form-submissions'],
          ['Growth', '/growth-submissions']
        ]
      ],
      ['mdi-page-layout-body', 'Pages', '/404', true,
        [
          ['"How We Recruit" Add', '/how-we-recruit'],
          ['"How We Recruit" View', '/how-we-recruit-view'],
        ]
      ],
      ['mdi-account-multiple', 'People', '/404', true,
        [
          ['Network View', '/network-view'],
          ['Employees', '/employees'],
          ['Talent', '/talent'],
          ['Add New Talent', '/talent/add'],
          ['Customers', '/customers'],
          ['Potential Customers', '/potential-customers']
        ]
      ],
      ['mdi-mail', 'Physical Mail', '/404', true,
        [
          ['Campaigns', '/physical-mail'],
          ['Template', '/physical-mail-template'],
        ]
      ],
      ['mdi-domain', 'Projects', '/404', true,
        [
          ['Project Overview', '/project-view'],
          ['Add New Project', '/project-add'],
          ['Connect Talent To Project', '/project-connect'],
        ]
      ],
      ['mdi-tag', 'Tags', '/404', true,
        [
          ['Tags Overview', '/tag-view'],
          ['Add New Tags', '/tag-add'],
        ]
      ],
      ['mdi-google-ads', 'Ad Accounts', '/404', true,
          [
              ['Google', '/ad-google'],
              ['Facebook & Instagram', '/ad-fb-insta']
          ]
      ],
      ['mdi-linkedin', 'Social Media', '/404', true,
        [
          ['Linkedin', '/sm-linkedin'],
          ['Instagram', '/sm-instagram'],
          ['Twitter', '/sm-twitter']
        ]
      ],
      ['mdi-bell', 'Notifications', '/notifications', false],
      ['mdi-account-cash', 'Financials', '/404', true,
          [
              ['Cash Flow', '/cash-flow'],
              ['Profit/Loss', '/profit-loss'],
              ['Rates Summary', '/rates-summary'],
              ['Projections', '/projections']
          ]
      ],
      ['mdi-cog', 'Properties', '/404', true,
        [
          ['Skills', '/skills'],
          ['Landing Pages', '/landing-pages'],
        ]
      ],
    ],
  }),
}
</script>

<style scoped>

</style>
