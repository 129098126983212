<template>
  <v-container fluid fill-height>
    <v-row align-center justify-center>
      <v-col xs="12" sm="8" md="4" offset-md="4">
        <v-card rounded :max-width="$vuetify.breakpoint.width / 3" :loading="loading">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Login - Slashdev</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-text-field
                  @keyup.enter="login"
                  id="email"
                  v-model="email"
                  prepend-icon="mdi-account"
                  name="email"
                  label="Email"
                  type="text"
              ></v-text-field>
              <v-text-field
                  @keyup.enter="login"
                  v-model="password"
                  id="password"
                  prepend-icon="mdi-lock"
                  name="password"
                  label="Password"
                  type="password"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="login" color="primary" :loading="loading">Login</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "LoginForm",

  data() {
    return {
      loading: false,
      email: "",
      password: "",
      errorResponse: null,
      hidePassword: true,
      error: false,
      showResult: false,
      result: "",
      rules: {
        required: (value) => !!value || "Required.",
      },
    };
  },

  methods: {
    login() {
      const self = this;

      if (this.loading)
        return

      this.loading = true

      axios.get(`${this.$store.state.apiUrl}/sanctum/csrf-cookie`).then(response => {
        console.log(response)
        axios
            .post(`${this.$store.state.apiUrl}/api/login`, {
              email: this.email,
              password: this.password,
            })
            .then(function (response) {
              self.result = response.data;
              self.$store.commit(
                  "changeAuthKey",
                  `${response.data.plainTextToken}`
              ); // Bearer : Key
              self.$cookies.set(
                  "slashdevAuth",
                  `${response.data.plainTextToken}`
              );
            })
            .catch(function (error) {
              self.result = error.response;
              self.errorResponse = error.response.data.message;
              self.loading = false
            });
      });
    },
  },
}
</script>

<style scoped>

</style>
