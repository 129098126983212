import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        drawer: false,
        apiUrl: process.env.VUE_APP_API_URL,
        production: process.env.VUE_APP_PRODUCTION,
        headers: {
            headers: {
                Authorization: '',
            },
        },
        snackbar: {
            visible: false,
            message: null,
            color: 'primary',
            timeout: 5000,
        },
        appLoaded: false,
        authKey: '',
        authKeyChecked: false,
        authInfo: {},
        darkMode: false,
        notifications: [],
    },
    getters: {
        userRoles: (state) => {
            if (
                Object.keys(state.authInfo).length === 0 &&
                state.authInfo.constructor === Object
            )
                return [];

            let roleNames = [];
            state.authInfo.roles.forEach((role) => roleNames.push(role.name));
            return roleNames;
        },

        isAdmin: (state, getters) => {
            if (state.authInfo === {}) return false;
            return getters.userRoles.includes('administrator');
        },

        storageUrl: () => {
            return process.env.VUE_APP_DO_SS_FULL_URL;
        },

        drawer: (state) => {
            return state.drawer
        },

        userFullname: (state) => {
            return state.authInfo.name
        }
    },

    mutations: {
        changeDrawer(state) {
            state.drawer = !state.drawer;
        },

        changeDarkMode(state) {
            localStorage.setItem('darkMode', `${!state.darkMode}`)
            state.darkMode = !state.darkMode;
        },

        changeAuthKey(state, authKey) {
            state.authKey = authKey;
            state.headers.headers.Authorization = authKey;
            state.authKeyChecked = true;
            // TODO: Add a check after we have retrieved the auth key in the login to make sure it works
        },

        changeAuthInfo(state, authInfo) {
            state.authInfo = authInfo;
            state.appLoaded = true;
        },

        changeNotifications(state, notifications) {
            state.notifications = notifications;
        },

        deleteNotification(state, notificationId) {
            state.notifications = state.notifications.filter(
                (t) => t.id !== notificationId,
            );
        },

        deleteAllNotifications(state) {
            state.notifications = [];
        },

        displaySnackbar(state, payload) {
            state.snackbar.visible = true;
            if (payload.message) state.snackbar.message = payload.message;

            if (payload.timeout) state.snackbar.timeout = payload.timeout;

            if (payload.color) state.snackbar.color = payload.color;
        },
    },
    actions: {},
});
