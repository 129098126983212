<template>
  <v-app>
    <AppBar v-if="this.$store.state.authKeyChecked"></AppBar>
    <LeftNavDrawer v-if="this.$store.state.authKeyChecked"></LeftNavDrawer>
    <v-main v-if="this.$store.state.authKeyChecked" style="background-color: lightgray">
      <router-view></router-view>
      <v-snackbar
          v-model="$store.state.snackbar.visible"
          :timeout="$store.state.snackbar.timeout"
          :color="$store.state.snackbar.color"
      >
        {{ $store.state.snackbar.message }}
      </v-snackbar>
    </v-main>
    <v-main v-if="!this.$store.state.authKeyChecked" style="background-color: black">
      <LoginForm></LoginForm>
    </v-main>
  </v-app>
</template>

<script>
import LeftNavDrawer from "@/components/drawers/LeftNavDrawer";
import AppBar from "@/components/bars/AppBar";
import LoginForm from "@/components/auth/LoginForm";
import axios from "axios";
export default {
  name: 'App',

  components: {
    LoginForm,
    AppBar,
    LeftNavDrawer,
  },

  computed: {
    production() {
      return process.env.VUE_APP_PRODUCTION
    }
  },

  updated() {
    this.getAuth()
  },

  data: () => ({
    //
  }),

  methods: {
    getAuth() {
      let self = this;

      if (
          this.$store.state.authKeyChecked &&
          Object.keys(this.$store.state.authInfo).length === 0
      )
          // Let's load the user object if we have authenticated but not retrieved the user data yet.
        axios
            .get(
                `${this.$store.state.apiUrl}/api/me`,
                this.$store.state.headers
            )
            .then((response) => {
              self.$store.commit("changeAuthInfo", response.data);
              if (self.$store.state.authInfo.role !== "admin") {
                self.$cookies.remove("slashdevAuth");
                self.$router.push("/login")
              }
            })
            .catch(() => {
              if (self.$cookies.isKey("slashdevAuth"))
                self.$cookies.remove("slashdevAuth");

              self.$router.push("/login")
            })
    },
  }
};
</script>
