<template>
  <v-app-bar
      app
      dark
      clipped-left
      color="primary"
      tile
      elevation="1"
  >
    <v-app-bar-nav-icon @click="$store.commit('changeDrawer')"></v-app-bar-nav-icon>
    <h2>Slashdev Admin - Logged in as: {{ $store.getters.userFullname }}</h2>
  </v-app-bar>
</template>

<script>
export default {
  name: "AppBar"
}
</script>

<style scoped>

</style>
