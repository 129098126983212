var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{attrs:{"mini-variant":_vm.$store.state.drawer,"app":"","dark":"","clipped":"","color":"primary"}},[_c('v-list',[_vm._l((_vm.singleItems),function(ref,i){
var icon = ref[0];
var title = ref[1];
var to = ref[2];
var group = ref[3];
return _c('v-list-item',{key:'singleItem-' + i,attrs:{"to":to}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(title))])],1)}),_vm._l((_vm.groupItems),function(ref,i){
var icon = ref[0];
var title = ref[1];
var to = ref[2];
var group = ref[3];
var groupItems = ref[4];
return _c('v-list-group',{key:'groupItem' + i,attrs:{"prepend-icon":icon,"active-class":"accent"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v(_vm._s(title))])]},proxy:true}],null,true)},_vm._l((groupItems),function(ref,i){
var gTitle = ref[0];
var gTo = ref[1];
return _c('v-list-item',{key:i,attrs:{"to":gTo,"active-class":"accent"}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(gTitle)}})],1)}),1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }